// 题库列表
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">培训题库</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input
                v-model="questionBankName"
                type="text"
                size="small"
                clearable
                placeholder="请输入题库名称"
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                :modal="false"
                typeStu
              />
            </span>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleAdd('add')"
                >新建</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="题库名称"
                align="left"
                prop="questionBankName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="培训类型"
                align="left"
                prop="categoryName"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="资源提供者"
                align="left"
                prop=""
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="类型"
                align="left"
                prop=""
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="操作"
                align="center"
                width="300px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleEdit('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDelete(scope.row)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "platformOfquestionBankList",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      questionBankName: "",
      // tree 参数
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };

      if (this.questionBankName) {
        params.questionBankName = this.questionBankName;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/special/exam/question/bank/page",
        params,
        pageNum,
      });
    },
    // 修改
    handleCreate(stu, id = "") {
      this.$router.push({
        path: "/web/operate/accountEdit",
        query: { id, stu },
      });
    },

    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 新增题库
    handleAdd() {
      this.$router.push({
        path: "/web/platformOfaddQuestionBank",
      });
    },
    // 编辑
    handleEdit(stu,row){
      ;
      this.$router.push({
        path:'/web/platformOfeditQuestionBank',
        query:{
          stu:'edit',
          questionBankId:row.questionBankId
        }
      })
    },
     handleDelete(row) {
      const that = this;
      that
        .$confirm(
          "你确定删除" + row.questionBankName + "题库吗?",
          "删除",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
            type: "warning",
          }
        )
        .then(() => {
          that
            .$post("/special/exam/question/bank/delete", { questionBankId:row.questionBankId })
            .then((ret) => {
              if (ret.status == 0) {
                that.getData();
              } else {
                that.$message({
                  type: "error",
                  message: ret.message,
                });
                
              }
            });
        })
        .catch(() => {
          return;
        });
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {},
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">

</style>

